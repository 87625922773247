@font-face {
  font-family: 'Poppins';
  src: url('../public/fonts/Poppins-Bold.woff2') format('woff2'),
    url('../public/fonts/Poppins-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../public/fonts/Poppins-Medium.woff2') format('woff2'),
    url('../public/fonts/Poppins-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../public/fonts/Poppins-Light.woff2') format('woff2'),
    url('../public/fonts/Poppins-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../public/fonts/Poppins-Regular.woff2') format('woff2'),
    url('../public/fonts/Poppins-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../public/fonts/Poppins-SemiBold.woff2') format('woff2'),
    url('../public/fonts/Poppins-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
html {
  scroll-behavior: smooth;
}
/* MY CSS */
.w-input {
  width: 73%;
}

body {
  font-family: 'Poppins' !important;
}

.select__multi-value {
  background-color: #fc407c !important;
}

.wrapperHome {
  overflow: hidden;
}

.container-width {
  max-width: 1650px;
  margin: 0 auto;
}

.arhamsoft-logo {
  position: absolute;
  z-index: 1;
}

.how-it-works .container {
  max-width: 1320px;
}

h1 {
  font-weight: 600;
  font-size: 55px;
  color: white;
  line-height: 72px;
  margin-bottom: 20px;
}

h2 {
  font-weight: 600;
  font-size: 48px;
  color: white;
}

h3 {
  font-weight: bold;
  font-size: 50px;
  color: white;
}

p {
  font-weight: 300;
  font-size: 16px;
  color: white;
  line-height: 22px;
}

.wrapper {
  height: unset;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #0f122247;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #E80F4D;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #E80F4D;
}

/* header */
.header {
  background-color: #050714;
  padding: 20px 0px;
  position: relative;
}

.home-header .navbar {
  background-color: unset;
  padding: 0.5rem 0px;
}

.home-header .navbar .navbar-brand {
  position: unset;
  margin-right: 1rem;
  font-size: 1.25rem;
  margin-left: 0px;
  margin-top: 0px;
}

.header-list .nav-link {
  font-weight: 500;
  color: white !important;
  font-size: 16px;
  margin: 0px 15px;
  cursor: pointer;
}

.header-list .nav-link:hover {
  color: #FF407C !important;
}

.header-list {
  display: flex;
  justify-content: space-between;

}

.header-list .navbar-nav {
  margin-left: 85px;
}

.btn-pink .address {
  overflow: hidden;
  width: 125px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  margin: 0 auto;
}

.header-buttons {
  display: flex;
  align-items: center;
}

.header-buttons .nav-item {
  list-style: none;
}

.home-header .btn-pink,
.main .btn-pink,
.about-us .btn-pink {
  background: linear-gradient(to right, #FF437E, #E80F4D) !important;
  ;
  border: 2px solid #FF7BA5 !important;
  color: white;
  border-radius: 26px !important;
  font-weight: 500;
  font-size: 16px;
  height: 50px;
  width: 194px;
  padding: 0px;
  margin: 0px;
  transform: translate(0px);
  transition: unset;
}

.home-header .btn-pink:hover,
.home-header .btn-pink:focus,
.main .btn-pink:hover,
.main .btn-pink:focus,
.about-us .btn-pink:hover,
.about-us .btn-pink:focus,
.featured-projects-button .btn-pink:focus,
.featured-projects-button .btn-pink:hover {
  background: transparent !important;
  color: white !important;
  box-shadow: unset !important;
  transform: unset;
}

.responsive-header {
  display: none;
}

.responsive-header .nav-item .address {
  display: block;
  white-space: nowrap;
  width: 71px;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #FF407C;
  border-radius: 10px;
  padding: 3px 9px;
  font-size: 12px;
}

.dropbtn {
  padding: 0.5rem 1rem;
  font-weight: 500;
  color: white;
  font-size: 16px;
  cursor: pointer;
  background-color: unset;
  border: unset;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.home-dropdown-content {
  display: none;
  position: absolute;
  background-color: #000;
  z-index: 1;
  min-width: 225px;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0px 0px 14px 2px #eb1654;
}

.home-dropdown-content .nav-link {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.home-dropdown-content .nav-link:hover {
  background-color: #000;
}

.dropdown:hover .home-dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  color: #FF407C;
}

.mobile-drop {
  display: none;
}

/* main */
.main {
  background-image: url('../public/images/main-bg.png');
  position: relative;
  padding: 40px 0px 50px 0px;
  background-repeat: no-repeat;
  background-color: #040715;

}

.main-content h5,
.about-us-content h5 {
  font-weight: 300;
  font-size: 26px;
  color: #FF407C;
  margin-bottom: 0.5rem;
}
.about-us-content .about-us-btn{
  cursor: pointer;
}

.main-content {
  padding-right: 30px;
}

.main-content p,
.about-us-content p {
  margin-bottom: 60px;
}

.main-buttons {
  gap: 25px;
}

.main-buttons .btn-pink,
.main-buttons .btn-transparent,
.about-us .btn-pink,
.main-buttons .btn-transparent {
  width: 160px;
}

.main-buttons .btn-transparent {
  background: transparent;
  height: 50px;
  border-radius: 26px;
  font-weight: 500;
  font-size: 16px;
  border: 1px solid #FF407C;
  color: white;
  padding: 0px;
}

.main-buttons .btn-transparent:hover,
.main-buttons .btn-transparent:active {
  background: linear-gradient(to right, #FF437E, #E80F4D) !important;
  border-color: unset !important;
  transform: translate(0px);
}

.main-buttons .btn-transparent:focus {
  box-shadow: unset !important;
  background: linear-gradient(to right, #FF437E, #E80F4D) !important;
  border-color: unset !important;
}

.main .container-width {
  position: relative;
}

/* images-section */
.images-section {
  background-color: #040715;
  padding: 10px 0px 90px;
}

.images-section-content {
  background-color: #0F1222BD;
  padding: 46px;
  min-height: 100%;
}

.images-section-content h3 {
  font-weight: 600;
  font-size: 26px;
  color: white;
  margin-bottom: 15px;
}

.images-section-content-img {
  background-color: #5142FC;
  border-radius: 11px;
  width: 70px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 45px;
  padding: 15px;
}

.bg-yellow {
  background-color: #FCBA30;
}

.bg-purple {
  background-color: #C20AD6;
}

.bg-pink {
  background-color: #FF407C;
}

/* about-us */
.about-us {
  background-color: #040715;
  padding: 80px 0px;
}

.video-container {
  position: relative;
  height: 100%;
}

.video-container #videoLink {
  height: 100% !important;
  width: 100% !important;
}

.video-container .react-player__preview {
  background-size: 100% !important;
  background-repeat: no-repeat;
}

.play-btn {
  width: 59px;
  height: 59px;
  background-color: #fff;
  border-radius: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);
  top: 46%;
  cursor: pointer;
}

.play-btn:after {
  content: "";
  position: absolute;
  left: 45%;
  top: 45%;
  height: 0;
  width: 0;
  margin: -12px 0 0 -6px;
  border: solid transparent;
  border-left-color: #d31f24;
  border-width: 16px 24px;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.pause-btn .video-pause {
  width: 59px;
  height: 59px;
  background-color: #fff;
  border-radius: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  top: 46%;
  color: #d31f24;
  transform: translateX(-50%);
  font-size: 21px;
  cursor: pointer;
}

.about-us-content p,
.how-it-works-headings p,
.footer-top-content p {
  line-height: 32px;
}

/* how-it-works */
.how-it-works {
  background-image: url('../public/images/how-it-works-bg.svg');
  background-repeat: no-repeat;
  background-color: #030310;
  background-position: center center;
  padding: 100px 0px 200px;
}

.how-it-works-headings {
  width: 1210px;
  margin: 0 auto 110px;
}

.how-it-works-headings h2 {
  margin-bottom: 20px;
}

.how-it-works-content {
  width: 210px;
  margin: 0 auto;
}

.how-it-works-content h5 {
  color: #5142FC;
  margin-top: 30px;
  font-size: 18px;
  margin-bottom: 0.5rem;
  line-height: 1.2;
}

.how-it-works-content-yellow h5 {
  color: #FCBA30;
}

.how-it-works-content-purple h5 {
  color: #C20AD6;
}

.how-it-works-content-pink h5 {
  color: #FF407C;
}

.how-it-works-content p {
  color: #ffffff91;
  margin-bottom: 30px;
}

.how-it-works .how-it-works-row {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 30;
  justify-content: center;
}

.how-it-works-col {
  width: 260px;
  padding: 0 25px;
  max-width: 100%;
}

.how-it-works .how-works-zikzak {
  position: absolute;
  z-index: -1;
}

.reponsive-works-area {
  display: none;
}

/* featured-projects */
.featured-projects {
  background-color: #030310;
  padding: 50px 0px;
}

.featured-projects .owl-theme .owl-dots .owl-dot {
  display: none;
}

.featured-projects .owl-carousel .owl-nav {
  display: block !important;
}

.featured-projects .owl-theme .owl-nav [class*=owl-]:hover {
  background: unset;
}

.featured-projects button.owl-next {
  position: absolute;
  right: -32px;
  top: 0;
  bottom: 0;
}

.featured-projects button.owl-prev {
  position: absolute;
  left: -32px;
  top: 0;
  bottom: 0;
}

.featured-projects .owl-carousel {
  margin-bottom: 50px;
}

/* .owl-carousel.owl-loaded {
  display: block;
  text-align: center;
  font-size: 21px;
  color: white;
} */
.featured-projects-button .btn-pink {
  background: linear-gradient(to right, #FF437E, #E80F4D) !important;
  border: 2px solid #FF7BA5 !important;
  color: white;
  border-radius: 26px !important;
  font-weight: 500;
  font-size: 16px;
}

/* pricing-plants */
.pricing-plans {
  background-color: #040715;
  padding: 80px 0px;
}

.pricing-plans-headings h3 {
  font-weight: 500;
}

.pricing-plans-headings h6,
.faqs-content h6 {
  font-weight: 500;
  color: #FF407C;
  font-size: 18px;
  margin-bottom: 30px;
}

.tabs-content {
  background-color: #0F1222;
  border: 2px solid #2D334E;
  border-radius: 12px;
  padding: 64px;
  height: 100%;
}

.tabs-content .list {
  text-align: left;
}

.tabs-content ul {
  padding: 0;
}

.tabs-content ul li {
  list-style: none;
}

.tabs-content .list .list-item {
  font-weight: normal;
  list-style: none;
  color: white;
  font-size: 18px;
  line-height: 40px;
  position: relative;
}

.tabs-content .list .list-item::before {
  content: '';
  position: absolute;
}

.tabs-content .title {
  font-weight: 500;
  color: #7E86A8;
  font-size: 18px;
  margin-bottom: 8px;
  display: block;
}

.btn-pricing {
  font-weight: 500;
  font-size: 18px;
  color: #ffffff70;
  border-radius: 6px;
  background: transparent;
  border: 2px solid #ffffff70;
  height: 48px;
  width: 170px;
}

.btn-pricing:hover {
  background-color: #FF407C;
  color: white;
  cursor: pointer;
}

.tabs-content.popular {
  position: relative;
}

.tabs-content .most-popular {
  position: absolute;
  background-color: #1385FF;
  color: white;
  border-radius: 23px;
  top: -22px;
  left: 50%;
  transform: translateX(-50%);
  height: 36px;
  width: 125px;
}

.nav-tabs-area .nav-tabs {
  border-bottom: unset;
  margin-bottom: 70px !important;
  gap: 20px;
}

.pricing-plans-headings {
  margin-bottom: 40px;
}

.nav-tabs-area .nav-tabs .nav-item.show .nav-link,
.nav-tabs-area .nav-tabs .nav-link.active {
  color: white !important;
  border-radius: 23px;
  background-color: #FCBA30;
  border: 2px solid #FCBA30 !important;
  height: 44px;
  width: 155px;
}

.nav-tabs-area .nav-tabs .nav-item.show .nav-link,
.nav-tabs-area .nav-tabs .nav-link.active:hover {
  border: unset;
}

.nav-tabs-area .nav-link {
  font-weight: 500;
  font-size: 16px;
  color: #7E86A8 !important;
  border: unset !important;
  cursor: pointer;
}

.nav-tabs-area .nav-link:hover {
  border-color: unset !important;
}

.nav-tabs-area .ul-bg {
  background: #7e86a84a;
  width: 19%;
  margin: 0 auto;
  border-radius: 6px;
  height: 65px;
  align-items: center;
  gap: 35px;
}

/* faqs */
.faqs {
  background-image: url(../public/images/faqs-bg.png);
  background-repeat: no-repeat;
  padding: 80px 0px;
  background-size: 100% 100%;
  margin-bottom: -1px;
}

.faqs-content h2 {
  margin-bottom: 30px;
}

.faqs .accordion-item h4 {
  font-weight: 500;
  font-size: 22px;
  color: white;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}

.faqs .accordion-body p {
  color: #ffffffa6;
  margin-bottom: 1rem;
}

.faq-accordion {
  height: 500px;
  overflow-y: auto;
  padding-right: 14px;
}

/* width */
.faq-accordion::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
.faq-accordion::-webkit-scrollbar-track {
  background: #0f122247;
}

/* Handle */
.faq-accordion::-webkit-scrollbar-thumb {
  background: #f8ac0d;
  border-radius: 5px;
}

/* Handle on hover */
.faq-accordion::-webkit-scrollbar-thumb:hover {
  background: #f8ac0d;
}

.faqs .accordion-item {
  background-color: transparent;
  border-bottom: 1px solid #ffffff45;
}

.faqs-content-area {
  padding-right: 130px;
}

.faqs .accordion-button {
  background-color: transparent !important;
}

.faqs .accordion-button:focus {
  border-color: unset;
  box-shadow: unset;
}

.faqs .accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 25px 0px;
  font-size: 1rem;
  text-align: left;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
}

.faqs-content-area .accordion-button::after {
  position: absolute;
  content: '';
  right: 0;
  clip-path: polygon(0 0, 100% 0, 50% 100%) !important;
  background-image: unset !important;
  background: #f8ac0d;
  height: 11px;
  width: 13px;
  transition: transform .2s ease-in-out;
}

.faqs-content-area .accordion-button:not(.collapsed)::after {
  transform: rotate(-180deg);
}

.faqs .accordion-body {
  padding: 5px 0px;
}

/* .faqs-content-area {
    padding-right: 210px;
  } */
/* form */
.faqs-form {
  background-color: #0F1222;
  border: 2px solid #2D334E;
  padding: 50px;
  border-radius: 8px;
}

.faqs-form-heading h3 {
  font-weight: 500;
  color: white;
  font-size: 40px;
  margin-bottom: 60px;
}

.faqs-form .form-label {
  font-weight: normal;
  color: white;
  font-size: 18px;
}

.faqs-form .form-control {
  background-color: transparent;
  border: initial;
  border-bottom: 1px solid #ffffff29;
  border-radius: 0px;
  padding: 0px 0px 5px;
  font-size: 15px;
  color: white;
  height: initial;
  line-height: inherit;
}

.faqs-form .form-control:focus {
  box-shadow: unset;
}

.faqs-form-content {
  margin-bottom: 25px;
}

.faqs-form-content:last-child {
  margin-bottom: 40px !important;
}

.faqs-form-button .faqs-button {
  font-weight: 500;
  font-size: 18px;
  color: white;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 27px;
  height: 46px;
  width: 200px;
  padding: 0px;
  background-image: unset;
  transition: all ease-in-out 0.5s;
}

.faqs-form-button .faqs-button:hover,
.faqs-form-button .faqs-button:focus {
  transform: scale(1.1);
  background-color: transparent !important;
  background-image: unset !important;
  box-shadow: unset;

}

.faqs-form-content textarea {
  margin-bottom: 00px;
}

.faqs-content-area .accordion-button::after {
  clip-path: polygon(0 0, 100% 0, 50% 100%) !important;
  background-image: unset !important;
  background: #f8ac0d;
  height: 11px;
  width: 13px;
}

/* footer */
.home-footer.footer {
  background-color: #040715;
  padding: 0px;
}
.footer-logo{
  max-width: 150px;
  margin-top: -28px;
}

.margin-content {
  /* width: 96px; */
  margin-bottom: 40px;
}

.footer-top {
  padding: 60px 0px;
}

.footer-top-content-heading h4 {
  font-weight: 500;
  color: white;
  font-size: 24px;
}

.footer-top ul li {
  font-weight: normal;
  color: #7E86A8;
  font-size: 18px;
  list-style: none;
  line-height: 50px;
}

.footer-top .social-links li {
  transition: all ease-in-out 0.5s;
  width: 47px;
}

.footer-top .social-links li:hover {
  transform: scale(1.2);
}

.footer-top .social-links li a:hover {
  border-bottom: none !important;
}

.footer-top ul li a {
  color: white !important;
  cursor: pointer;
  text-decoration: none;
}

.footer-top ul li a:hover {
  border-bottom: 1px solid;
}

.footer-top-content p {
  color: white;
}

.social-links {
  gap: 20px;
  margin-bottom: 72px;
}

.footer-platform-btn .join-btn {
  font-weight: 500;
  background-color: #FD9D27;
  color: white;
  font-size: 18px;
  border-radius: 27px;
  width: 100%;
  height: 58px;
  border: 2px solid #FD9D27;
  background: #FD9D27;
  padding: 0px;
}

.footer-platform-btn .join-btn:hover,
.home-footer .footer-platform-btn .join-btn:focus {
  background: transparent;
  transition: unset;
  transform: unset;
  background-color: transparent !important;
  background-image: unset !important;
}

.social-links-wrapper {
  padding-left: 220px;
}

.footer-bottom {
  padding: 40px 0px;
  border-top: 1px solid #ffffff1f;
}

.footer-bottom-content p {
  color: #7E86A8;
}

/* login-header */
.login-header {
  background-color: #040715;
}

.login-main {
  background-color: #040715;
  padding: 90px 0px;
  min-height: calc(100vh - 216px);
}

/* login-main */
.login-header {
  background-color: #040715 !important;
}

.login-header .navbar {
  background: unset !important;
}

.main-login-form-area {
  width: 38%;
  margin: 0 auto;
}

/* .main-login-form-area h2 {
  font-weight: 500;
  font-size: 40px;
  color: white;
  margin-bottom: 50px;
} */

.main-login-form-area .nav-tabs .nav-link {
  color: #C6CADE;
  font-size: 18px;
  font-weight: normal;
  cursor: pointer;
}

.main-login-form-area .nav-tabs .nav-link:hover {
  border-color: unset !important;
  border: unset !important;
  outline: unset !important;
}

.main-login-form-area .nav-tabs-area .nav-tabs .nav-item.show .nav-link,
.main-login-form-area .nav-tabs .nav-link.active {
  color: #C6CADE;
  background-color: #0F1222;
  border: 2px solid #0F1222 !important;
  border-radius: 0px;
  height: 44px;
  width: 175px;
  font-weight: bold;
}

.main-login-form-area .nav-tabs {
  border: unset !important;
}

.main-login-form-area .ul-login-tabs {
  gap: 20px;
  margin-bottom: 40px;
}

.login-input-wrapper label,
.account-input-wrapper label {
  font-size: 18px;
  font-weight: normal;
  color: #7E86A8;
}

.login-input-wrapper,
.account-input-wrapper {
  margin-bottom: 40px;
}

.login-input-wrapper input,
.account-input-wrapper input {
  background-color: transparent;
  border-radius: 6px;
  border: 1.5px solid #2D334E;
  color: white !important;
}

.login-input-wrapper input:focus,
.account-input-wrapper input:focus {
  background-color: unset;
  border-color: #2D334E;
  box-shadow: unset;
}

.login-form .login-btn {
  background: transparent linear-gradient(93deg, #FF437E 0%, #E80F4D 100%);
  border: 1px solid #FF7BA5;
  border-radius: 23px;
  width: 100%;
  color: white;
  height: 47px;
  margin-bottom: 25px;
  transition: all 0.35s;
}

.login-form .login-btn:hover,
.login-form .login-btn:active {
  transform: scale(1.04);
  border: 1px solid #FF7BA5;
}

.login-form .login-btn:focus,
.login-form .login-btn:hover,
.login-form .login-btn:active,
.login-form .login-btn:focus:active {
  background: transparent linear-gradient(93deg, #FF437E 0%, #E80F4D 100%) !important;
  box-shadow: unset !important;
  /* background-image: none !important; */
  background-color: none !important;
  border-color: #FF7BA5 !important;
}

.login-form .forgot-password {
  color: #FCBA30;
  text-decoration: none;
}

.login-form .forgot-password:hover {
  text-decoration: underline;
}

/* created-nft */
.blur-wrapper {
  /* position: fixed; */
  height: 100vh;
}

.blur-content-area {
  position: absolute;
  background-color: #2b2c2af3;
  z-index: 300;
  height: 100%;
  width: 100%;
}

.blur-content h3 {
  font-size: 30px;
  color: #ffff;
}

.blur-content button {
  background-color: black;
  border: 2px solid black;
  border-radius: 30px;
  padding: 14px 30px;
  font-weight: 500;
  font-size: 16px;
  color: white;
}

.blur-content button:hover {
  background-color: white;
  color: black;
  cursor: pointer;
}

.created-nft-wrapper-area {
  background-color: #040715;
  min-height: 100vh;
}

.dashboard-area {
  background-color: #0F1222;
  max-width: 100%;
  width: 340px;
  padding: 34px;
  /* height: 100vh; */
  position: relative;
}
.header-logo{
  width: 150px;
}
.site-logo{
  margin-bottom: 50px;
  display: inline-block;
}
.site-logo .logo {
  width: 150px;
}

.dashboard-list .list-item {
  font-size: 18px;
  line-height: 60px;
  list-style: none;
  font-weight: 500;
}

.dashboard-list .list-item a {
  text-decoration: none;
  color: #7E86A8;
  position: relative;
  cursor: pointer;
}

.dashboard-list .list-item a:hover {
  color: white;
}

.dashboard-list .list-item .active {
  color: white;
  position: relative;
}

.tabs-list .list-item .active {
  background-color: #fc407cdb;
  width: 100%;
  border-radius: 5px;
}

.dashboard-list .list-item .active svg path {
  fill: #FC407C;
}

.dashboard-list .list-item .active::before {
  border-radius: 0px 10px 10px 0px;
  content: '';
  position: absolute;
  background-color: #FC407C;
  height: 45%;
  width: 5px;
  left: -34px;
}

.dashboard-list .list-item a:hover::before {
  border-radius: 0px 10px 10px 0px;
  content: '';
  position: absolute;
  background-color: #FC407C;
  height: 45%;
  width: 5px;
  left: -34px;
}

.dashboard-list .list-item a:hover svg path {
  fill: #FC407C;
}

.dashboard-list .list-item a svg {
  margin-right: 20px;
}

.dashboard-area .main-show {
  font-size: 16px;
  color: #535D84;
  font-weight: normal;
  margin-bottom: 20px;
  display: block;
}

.created-nft-header-input {
  position: relative;
  width: 550px;
  height: 60px;
}

.mobile-header-input {
  display: none;
}

.created-nft-header {
  margin-bottom: 50px;
}

.created-nft-header-input input {
  width: 100%;
  background-color: #14182B;
  border-radius: 35px;
  height: 100%;
  border: 1px solid #14182B;
  padding-left: 70px;
  color: white;
}

.created-nft-header-input input::placeholder {
  color: #505155;
  font-size: 16px;
  font-weight: 500;
}

.created-nft-header-input input:focus-visible {
  outline: none;
}

.created-nft-header-input i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 31px;
  color: #C220D6;
  font-size: 22px;
}

/* second */
.created-nft-right-area {
  height: 100%;
  background-color: #040715;
  width: calc(100% - 310px);
  padding: 34px;
  flex: 1;
  position: relative;
}

.created-nft-right-area .toggler-bars {
  position: absolute;
  left: 0px;
  color: #fc407c;
  background-color: white;
  height: 25px;
  width: 33px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 20px;
  z-index: 111;
  top: 15px;
  cursor: pointer;
  display: none;
}

.created-nft-header-subscription .dropdown button {
  color: white;
  /* background-color: #fff !important; */
  background-image: unset !important;
  border-radius: 50px;
  gap: 30px;
  width: 270px;
  height: 60px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

.profile-dropdown button {
  width: 60px !important;
  padding: 0px;
  border-radius: 50%;
}

.profile-dropdown .dropdown-menu {
  background-color: black !important;
  width: 0px !important;
  padding: 5px !important;
  border: 1px solid #fc407c;
}

.profile-dropdown .dropdown-item {
  color: white !important;
  background-color: transparent;
  margin: 0px 0px 5px;
  font-size: 14px;
}

.profile-dropdown .dropdown-item:hover {
  background-color: #fc407c !important;
  border-radius: 5px !important;
}

.profile-dropdown .dropdown-menu::before {
  border: unset;
}

.created-nft-header-subscription .dropdown button:focus {
  box-shadow: unset;
}

.created-nft-header-subscription .dropdown button h4 {
  font-size: 16px;
  font-weight: 500;
}

.created-nft-header-subscription {
  gap: 25px;
}

.created-nft-header-subscription .dropdown-menu {
  background-color: #5142FB;
  width: 270px;
  border-radius: 12px;
  padding: 10px;
}

.created-nft-header-subscription .dropdown-item {
  color: white;
}

.created-nft-header-subscription .dropdown-item:hover,
.created-nft-header-subscription .dropdown-item:active {
  color: black;
  border-radius: 35px;
  background-color: white;
}

.bell-icon a,
.emoji-icon a {
  text-decoration: none;
}

.bell-icon i {
  color: #FCBA30;
  font-size: 25px;
  border-radius: 50px;
  background: #2D3148;
  height: 60px;
  width: 60px;
}

.emoji-icon {
  border-radius: 50%;
  background-color: white !important;
  height: 100%;
  width: 100%;
  background: white !important;
}

.emoji-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.created-nft-main-section-headings h2 {
  font-size: 26px;
  color: white;
  font-weight: 500;
}

.created-nft-main-section-headings a {
  font-size: 20px;
  color: #FC407C;
  font-weight: 500;
  text-decoration: none;
}

.created-nft-main-section-headings {
  margin-bottom: 30px;
}

/* nav-tabs */
.created-nft-tabs {
  margin-bottom: 40px;
}

.created-nft-tabs .nav-tabs {
  border-bottom: unset !important;
  margin-bottom: 25px;
}

.created-nft-tabs .nav-item {
  margin-right: 20px;
  cursor: pointer;
}

.created-nft-tabs ul {
  border-bottom: none;
  gap: 25px;
  margin-bottom: 40px;
}

.created-nft-tabs .nav-tabs .nav-item.show .nav-link,
.created-nft-tabs .nav-tabs .nav-link.active {
  color: #5142FB;
  background-color: #fff;
}

.created-nft-tabs .nav-tabs .nav-link {
  font-size: 18px;
  font-weight: 500;
  color: #7E86A8;
  border-radius: 27px;
  background-color: #7e86a870;
  margin-bottom: 20px;
}

.created-nft-tabs-description {
  border-radius: 24px;
  background-color: #0F1222;
  padding: 15px;
  margin-bottom: 30px;
}

.created-nft-header-subscription .address {
  overflow: hidden;
  width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 15px;
}

.created-nft-header-subscription-img {
  background-color: #202439;
  border-radius: 24px;
  padding: 30px;
  margin-bottom: 20px;
}

.created-nft-header-subscription-img-likes h4 {
  font-weight: 500;
  font-size: 15px;
  color: #ffffff91;
}

.created-nft-tabs-description h3 {
  font-size: 20px;
  color: white;
  font-weight: 600;
  margin-bottom: 30px;
  word-break: break-all;
}

.created-nft-header-subscription-img-currency h4 {
  font-size: 18px;
  color: #ffffff91;
  font-weight: 500;
}

.created-nft-header-subscription-img-currency .currency-color,
.created-nft-header-subscription-img-currency h4 i {
  color: #FCBA30;
}

.created-nft-header-subscription-img-currency .cr-logo {
  font-weight: normal;
}

/* table-section */
.table-bg {
  background-color: #0F1222;
  border-radius: 20px;
  padding: 0px 35px;
}

.table-bg tbody,
td,
tfoot,
thead,
tr {
  border-style: unset;
}

.table-bg tbody .border-th {
  border-style: unset !important;
}

.table-bg th {
  color: #ffffff59;
  font-size: 15px;
  font-weight: 500;
}

.table-bg td {
  color: white;
  font-size: 16px;
  font-weight: 600;
}

.table-bg th,
.table-bg td {
  padding: 18px 50px !important;
}

/* right your balance */
.your-balance-area {
  background-color: #0F1222;
  border-radius: 20px;
  padding: 35px;
  margin-bottom: 45px;
}

.your-balance-area h3 {
  font-size: 30px;
  font-weight: 500;
  color: white;
}

.your-balance-area a {
  text-decoration: none;
  color: #ffffff91;
  font-size: 15px;
  font-weight: normal;
  margin-bottom: 20px;
  display: block;
}

.your-balance-area h2 {
  color: white;
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 20px;
}

.total-offers {
  margin-bottom: 20px;
}

.total-offers h4 {
  font-size: 19px;
  color: #ffffff91;
  font-weight: 600;
}

.total-offers-left {
  gap: 15px;
}

.total-offers-left a {
  text-decoration: none;
}

.offer-yellow {
  color: #FCBA30;
  margin-left: 10px;
}

.light-gray-btn {
  font-size: 16px;
  font-weight: 600;
  color: #41465E;
  background: #7E86A8;
  border-radius: 37px;
  width: 100%;
  height: 60px;
}

.log-histroy {
  background-color: #0F1222;
  border-radius: 20px;
  padding: 35px;
  list-style: none;
}

.log-histroy .list-item {
  color: white;
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  border-bottom: 1px solid #ffffff2e;
  padding: 27px 0px;
}

/* manage-nfts */
.manage-nfts-main-section {
  background-color: #0F1222;
  border-radius: 20px;
  padding: 35px;
}

.cb-wrapper {
  padding: 35px 35px 0px;
}

.current-plan ul li {
  list-style: none;
  line-height: 40px;
  font-size: 16px;
}

.current-plan ul li:hover {
  background-color: #040715;
  border-left: 4px solid #e2134f;
}

.current-plan-box span {
  font-size: 18px;
}

.current-plan.design .generate {
  width: unset !important;
  margin: 0 auto;
}

.current-plan.design .main-heading {
  position: relative;
  padding-bottom: 10px;
  border-bottom: 2px solid #ea135199;
}

.current-plan.design .box {
  background-color: #0F1222;
  min-height: 340px;
  padding: 35px;
  border-radius: 12px;
  position: relative;
}

.current-plan.design .box::before {
  position: absolute;
  content: '';
  background-color: #ea1351f5;
  top: 0;
  height: 9px;
  width: 100%;
  left: 0;
  border-radius: 5px 5px 0px 0px;
}

.current-plan.design .heading h4 {
  font-size: 24px;
  position: relative;
  border-bottom: 2px solid #ea1351f5;
  display: inline-block;
  padding-bottom: 8px;
  font-weight: bold;
  margin-bottom: 18px;
}

.billing-list-info {
  padding: 20px 0px;
  border-top: 2px solid #3c3c3c;
}

.billing-list-info-content h4 {
  font-weight: bold;
}

/* security checkbox */
.security-checkbox input[type="radio"],
input[type="checkbox"] {
  width: 25px;
}

.security-checkbox .badge-success {
  color: #ffffff;
  background-color: #da3a6f !important;
  padding: 12px 22px;
  font-size: 14px;
}


.manage-nfts-main-section-badges {
  margin-bottom: 60px;
}

.manage-nfts-main-section-content h3,
.nft-preview-content h3 {
  font-size: 22px;
  color: white;
  font-weight: 500;
  margin-bottom: 35px;
}

.manage-nfts-main-section-badges-content {
  flex: 1;
}

.manage-nfts-main-section-badges h4 {
  font-size: 14px;
  font-weight: 500;
  color: #ffffff75;
}

.h4-first {
  width: 145px;
}

.h4-second {
  width: 202px;
}

.manage-nfts-main-section-badges-content span {
  font-size: 12px;
  font-weight: 500;
  color: #BBC0D8;
  background-color: #7e86a88c;
  border-radius: 15px;
  padding: 10px 14px;
  margin: 0px 4px;
}

.manage-nfts-accordion {
  border-top: 1px solid #80808052;
  margin-bottom: 80px;
}

.manage-nfts-accordion h3 {
  font-size: 22px;
  color: white;
  font-weight: 500;
}

.manage-nfts-accordion .accordion-button,
.manage-nfts-accordion .accordion-item {
  background-color: transparent;
}

.manage-nfts-accordion .accordion-button {
  padding: 30px 0px;
  border: unset;
}

.manage-nfts-accordion .accordion-item {
  border-bottom: 1px solid #80808052;
}

.manage-nfts-accordion .accordion-button:focus {
  border: unset !important;
  box-shadow: unset;
}

.select-content h5,
.file-uploader h5 {
  font-size: 16px;
  font-weight: 500;
  color: #ffffff75;
  margin-bottom: 15px;
}

.accordion-button::after {
  clip-path: polygon(100% 0, 0 0, 50% 100%);
  background-image: unset !important;
  background-color: white;
  height: 9px;
  width: 11px;
}

.accordian-images-wrapper {
  gap: 13px;
}

.accordian-images-wrapper-img {
  border: 1px solid gray;
  height: 100px;
  width: 100px;
}

.select-content {
  margin-bottom: 30px;
}

.select-content select {
  width: 460px;
  border-radius: 10px;
  border: 1px solid #7E86A8;
  background-color: transparent;
  color: white;
}

.select-content select option {
  background-color: #040715;
  color: white;
}

.select-content select:focus {
  border-color: #7E86A8;
  box-shadow: unset;
}

.uploader-content {
  border-radius: 20px;
  background-color: #202439;
  padding: 50px 0px;
  position: relative;
}

.uploader-content h5 {
  color: white;
  font-size: 16px;
  font-weight: 500;
}

.upload-logo {
  margin-bottom: 20px;
}

.uploader-content span {
  font-size: 12px;
  color: #ffffff7a;
  font-weight: normal;
  display: block;
  margin-bottom: 30px;
}

.uploader-content a {
  text-decoration: none;
  color: #FC407C;
  font-size: 14px;
  font-weight: 500;
}

.choose-file {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.generate {
  width: 100%;
  font-size: 16px;
  color: white;
  font-weight: 500;
  background: transparent linear-gradient(93deg, #FF437E 0%, #E80F4D 100%);
  border: 1px solid #FF7BA5;
  border-radius: 23px;
  height: 50px;
}

.padding-avatar {
  padding: 100px 0px;
}

/* profile */
.profile-nft-main-section .profile-area {
  background-color: #202439;
  border-radius: 10px;
  padding: 30px;
  padding-right: 0px;
  margin-bottom: 80px;
}

.profile-area-content-text h4 {
  font-size: 20px;
  color: white;
  font-weight: 600;
  margin-bottom: 20px;
}

.profile-area-content-text h5 {
  font-size: 18px;
  color: #ffffff8c;
  font-weight: 500;
}

.profile-area-content {
  gap: 45px;
}

.profile-area-edit-content {
  height: 100%;
}

.checkbox-area .form-check.form-switch {
  margin-bottom: 35px;
}

.checkbox-area .form-check.form-switch label {
  font-size: 15px;
  color: white;
  font-weight: normal;
}

.checkbox-area .form-check-input {
  background-color: #2CCD9A;
  height: 20px;
}

.checkbox-area .form-check-input:focus {
  box-shadow: unset;
  border-color: unset;
}

.checkbox-area .form-check-input:checked {
  background-color: white;
}

.checkbox-area .form-switch .form-check-input {
  background-image: url('../public/images/toggle-switch.svg');
}

.form-wrapper,
.checkbox-area {
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.to-do-list-heading-area-date h5 {
  font-size: 14px;
  color: #7E86A8;
  font-weight: normal;
  line-height: 18px;
}

.to-do-list-heading-area-content .plus-icon {
  color: white;
}

.to-do-list-heading-area-content .dots-3-icon {
  color: white;
}

.y-hidden {
  overflow-y: hidden !important;
}

/* .card {
    margin-bottom: 0;
  } */
/* hoem slider card */
.home-slider-card {
  padding: 30px;
  border: 1px solid #8080805e;
}

.home-slider-card .item h4 {
  color: white;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
}

.home-slider-card .featured-projects-img {
  margin-bottom: 20px;
}

.home-slider-card .item h5 {
  color: white;
  font-size: 15px;
  font-weight: normal;
  margin-bottom: 20px;
}

.home-slider-card .item h5:last-child {
  margin-bottom: 0px;
}

/* admin dashboard updated css */
.sidebar,
.card-scroll .table-responsive {
  padding-right: 4px;
  padding-bottom: 4px;
}

/* width */
.sidebar .sidebar-wrapper::-webkit-scrollbar,
.card-scroll .table-responsive::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
.sidebar .sidebar-wrapper::-webkit-scrollbar-track,
.card-scroll .table-responsive::-webkit-scrollbar-track {
  background: unset;
}

/* Handle */
.sidebar .sidebar-wrapper::-webkit-scrollbar-thumb,
.card-scroll .table-responsive::-webkit-scrollbar-thumb {
  background: #E80F4D;
  border-radius: 15px;
}

/* Handle on hover */
.sidebar .sidebar-wrapper::-webkit-scrollbar-thumb:hover,
.card-scroll .table-responsive::-webkit-scrollbar-thumb:hover {
  background: #E80F4D;
}

.main-panel>.content {
  padding: 78px 30px 30px 380px;
}

.sidebar,
.off-canvas-sidebar {
  width: 330px;
}

.sidebar-wrapper ul li .nav-link {
  padding: 0px 0px !important;
}

.sidebar-wrapper ul li {
  padding: 10px 25px;
  cursor: pointer;
}

/* .sidebar-wrapper ul li:hover {
  background: transparent linear-gradient(93deg, #FF437E 0%, #E80F4D 100%);
}
.sidebar-wrapper li.active {
  background: transparent linear-gradient(93deg, #FF437E 0%, #E80F4D 100%);
} */

.sidebar .nav li.active>a:not([data-toggle="collapse"]):before,
.off-canvas-sidebar .nav li.active>a:not([data-toggle="collapse"]):before {
  top: 50%;
  transform: translateX(-50%);
  left: -6px;
}

.sidebar-wrapper .MuiCollapse-wrapperInner {
  padding-left: 15px;
}

.sidebar-wrapper .CMSSubMenuItems {
  padding-left: 27px;
  cursor: pointer;
}

/* ck editor */
.ck.ck-editor__main>.ck-editor__editable {
  background: #27293d !important;
}

.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
  border-color: #27293d !important;
}

.ck.ck-reset_all,
.ck.ck-reset_all * {
  color: white !important;
}

.ck .ck-sticky-panel__content {
  border: 1px solid #27293d !important;
}

.ck.ck-toolbar {
  background: #27293d !important;
  border: unset !important;
}

.ck.ck-toolbar.ck-toolbar_grouping>.ck-toolbar__items {
  border-bottom: 1px solid #2b3553 !important;
}

.ck.ck-editor {
  border: 1px solid #2b3553 !important;
}

.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
  border: unset !important;
}

.ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button:not(.ck-disabled):hover,
.ck.ck-button.ck-on,
a.ck.ck-button.ck-on,
.ck.ck-button.ck-on:not(.ck-disabled):active,
a.ck.ck-button.ck-on:not(.ck-disabled):active,
.ck.ck-button.ck-on:not(.ck-disabled):hover,
a.ck.ck-button.ck-on:not(.ck-disabled):hover {
  background: unset !important;
}

.ck.ck-reset_all,
.ck.ck-reset_all * {
  color: white !important;
  background: #27293d !important;
}

.ck.ck-list__item .ck-button.ck-on,
.ck.ck-list__item .ck-button.ck-on:hover:not(.ck-disabled),
.ck.ck-list__item .ck-button:hover:not(.ck-disabled) {
  background: #27293d !important;
}

.has-label.form-group.user .ck.ck-reset_all,
.ck.ck-reset_all * {
  background: #0f1222;
}

.has-label.form-group.user .ck.ck-editor__main>.ck-editor__editable {
  background: #0f1222 !important;
}

/* multi select */
.has-label.form-group.user .css-ct81de-control {
  background: #0f1222 !important;
}

.modal-dark .modal-title {
  color: white !important;
  font-weight: 600;
  font-size: 20px;
}

.modal-dark.modal-dialog {
  max-width: 500px;
  margin: 1.75rem auto;
}

.modal-dark .modal-content {
  box-shadow: 1px 1px 16px 7px #746c6c38;
  background-color: #040714;
  padding: 15px;
}

.modal-dark .btn-modal {
  background: #e02058 !important;
  padding: 14px 34px !important;
  font-size: 16px;
}

.modal-dark .modal-body input {
  padding: 8px;
  margin-bottom: 15px;
}

/* checkout */
.checkout-content p {
  font-size: 20px;
  font-weight: normal;
}

.checkout-content a {
  font-size: 20px;
  color: #fd3e79;
  background-color: #3a2323;
  padding: 20px 45px;
  border-radius: 12px;
  display: inline-block;
}

/* my nfts */
.my-nfts-content {
  padding: 100px 0px;
}

.my-nfts-content a {
  padding: 0px;
  background-color: unset;
  text-decoration: underline;
}

/* table */
.thead-wrapper th span:hover {
  color: white;
}

.card label,
label {
  color: white;
  font-size: 16px;
  margin-bottom: 5px;
}

.dashboard-list li a span {
  color: #fff;
}

.dashboard-box p {
  font-size: 18px;
}

/* .dashboard-box button {
  background: #c652e8 !important;
  font-size: 18px;
  padding: 15px;
} */

.text-wrapper p {
  width: 230px;
}

.dashboard-box .wallet-info {
  overflow: hidden;
  width: 610px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 22px;
}

.dashboard-box p {
  overflow: hidden;
  width: 290px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 22px;
}

.dashboard-box .copy-text {
  cursor: pointer;
}

.dashboard-box a {
  font-size: 20px;
  color: white !important;
}

.copy {
  color: #FC407C;
}

.about-us .video-react .video-react-big-play-button {
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}

.about-us .video-react.video-react-fluid,
.video-react.video-react-16-9,
.video-react.video-react-4-3 {
  width: 722px;
}

.has-label.form-group.w73.user svg path {
  fill: white;
}

.contact-form-error {
  color: #cb2929;
  font-size: 13px;
}

/* admin profile */
.admin-dashboard-profile-dd .dropdown-menu {
  background-color: black !important;
  width: 0px !important;
  padding: 5px !important;
  border: 1px solid #fc407c;
}

.admin-dashboard-profile-dd .dropdown-item {
  color: white !important;
  background-color: transparent;
  margin: 0px 0px 5px;
  font-size: 14px;
}

.admin-dashboard-profile-dd .dropdown-menu:before {
  border: unset;
}

.admin-dashboard-profile-dd .dropdown-item:hover {
  background-color: #fc407c !important;
  border-radius: 5px !important;
}

.user-icons button {
  border-color: white !important;
}

.user-icons i {
  color: white;
}

.content-wrapper-list {
  max-height: 370px;
  background-color: #32364e91;
  width: 650px;
  padding: 20px;
  overflow: auto;
  height: auto;
  white-space: nowrap;
}

.makeStyles-ul-30 .MuiPaginationItem-root {
  color: #ffffff !important;
}

.user-icons .btn-theme {
  background: #fc407c;
  padding: 12px 26px;
  font-size: 15px;
  color: white !important;
  border: 2px solid #fc407c !important;
}

.btn-theme {
  background: #fc407c;
  padding: 12px 26px;
  font-size: 15px;
  color: white !important;
  border: 2px solid #fc407c !important;
}

.btn-theme:hover,
.btn-theme:active,
.btn-theme:focus,
.btn-theme:active:focus {
  background: transparent !important;
  color: #fc407c !important;
  border-color: #fc407c !important;
  background-image: none !important;
}

.btn-theme-two {
  color: white !important;
  font-size: 15px;
  padding: 12px 26px;
  background: #F8AC0D !important;
  border: 2px solid #F8AC0D;
  box-shadow: unset !important;
}

.btn-theme-two:hover,
.btn-theme-two:active,
.btn-theme-two:focus,
.btn-theme-two:active:focus {
  background: transparent !important;
  color: #F8AC0D !important;
  border-color: #F8AC0D !important;
  background-image: none !important;
}

.btn-white {
  background: white !important;
  border: 2px solid white;
  border-radius: 6px !important;
  padding: 12px 26px !important;
  height: unset !important;
  width: unset !important;
}

.btn-white:hover,
.btn-white:active,
.btn-white:focus,
.btn-white:active:focus {
  background: transparent !important;
  border-color: white;
  background-image: none !important;
}

.btn-white:hover h4,
.btn-white:active h4,
.btn-white:focus h4,
.btn-white:active:focus h4 {
  color: white;
}

.btn-white h4 {
  color: black;
}

.sidebar[data="blue"],
.off-canvas-sidebar[data="blue"] {
  background: #0f1222;
}

.main-panel {
  background: #040715;
}

.card {
  background: #0f1222;
}

.upload-multi-label {
  border: 1px solid white;
}

.upload-multi-label label::after {
  border-left: 1px solid white;
}

.upload-multi-label label {
  line-height: 25px;
  margin-bottom: 0px;
  width: 75%;
  position: static;
  border: none;
  height: 38px;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

/* checkout form */
.checkout-form-wrapper {
  padding: 100px 0px;
  height: calc(100vh - 220px);
  min-height: 650px;
}

.checkout-form-box {
  width: 700px;
  background-color: #040715bf;
  padding: 35px;
  margin: 0 auto;
  border-radius: 8px;
}

.checkout-form-wrapper h2 {
  font-size: 30px;
}

.checkout-form .input-wrapper input {
  padding: 22px 15px;
  font-size: 15px;
  background-color: #8b83833d;
}

.input-wrapper-handler {
  border: 1px solid #2b3553;
  border-radius: 0.4285rem;
  font-size: .75rem;
  padding: 13px 15px;
  transition: color .3s ease-in-out, border-color .3s ease-in-out, background-color .3s ease-in-out;
}

.input-wrapper-handler .ElementsApp .Icon-fill {
  fill: #ffffff;
}

.logo-admin-header {
  width: 80px;
}

.logo-admin-header img {
  width: 200%;
  height: 200%
}

.form-center .tab-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-center .tab-content>.active {
  max-width: 500px;
  width: 100%;
}

.form-center .w-45 {
  width: 100% !important;
}

.form-center .form-group {
  width: 100%;
}

.MuiTableSortLabel-root:focus {
  color: white !important;
}

.admin .text-primary {
  color: #ffffff !important;
}

.help-content .help-icon {
  background-color: #fc407c;
  padding: 8px 11px;
  border-radius: 50%;
  cursor: pointer;
  margin-bottom: 5px;
  border: 2px solid #fc407c;
}

.help-content .help-icon:hover {
  background-color: transparent;
}

.modal-help .modal-dialog {
  margin: 1.75rem auto !important;
  max-width: 700px;
}

.modal-help .modal-header {
  padding: 0px;
}

.modal-help .modal-content .modal-header button {
  display: none;
}

.modal-help .modal-header {
  padding: 0px;
}

.nft-modal-footer {
  border-top: 2px solid #fff !important;
}

.modal-help .modal-body h3 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}
.modal-help .modal-body ul li{
  font-size: 16px;
  font-weight: 300;
}
.modal-help .modal-dialog{
  height: 660px !important;
}
.modal-help .modal-content{
height: 100%;
overflow: scroll;
}

.admin .MuiPagination-text ul li .Mui-selected,
.admin .MuiPagination-text ul li .Mui-selected:hover,
.created-nft-right-area .MuiPagination-text ul li .Mui-selected,
.created-nft-right-area .MuiPagination-text ul li .Mui-selected:hover {
  background-color: #fc407c;
}

/* general */
/* .privacy-list {
  background-color: #fd3e79;
  border-radius: 12px;
  padding: 60px 45px;
} */

.all-content-page {
  background-color: white;
}
.privacy-list ul{
  padding-left: 0;
}
.privacy-list ul li {
  font-size: 15px;
  line-height: 30px;
  list-style: none;
  position: relative;
  padding-left: 25px;
  color: black;
}

.privacy-list ul li::before {
  content: '';
  position: absolute;
  background-color: #f32865;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  left: 0;
  top: 11px;
}
.privacy-list h2 {
  font-size: 32px;
  font-weight: bold;
  color: black;
}
.privacy-list h3 {
  font-size: 26px;
  font-weight: bold;
  color: black;
}
.privacy-list h4 {
  font-size: 22px;
  font-weight: bold;
  color: black;
}
.privacy-list h5 {
  font-size: 18px;
  color: black;
}
.privacy-list p{
  color: black;
}

.MuiTableContainer-root {
  white-space: nowrap;
}

.main-panel {
  border-top: unset;
}

.twofa-header .modal-title {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 600;
}

.has-danger.position-relative:after {
  right: 55px;
}

.has-danger.position-relative.ckeditor:after {
  right: 35px;
}

.has-success.position-relative:after {
  right: 35px;
}

.modal-help .modal-dialog,
.modal-dialog.twofamodal,
.two-fa-modal-login {
  height: 100%;
  transform: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-heading h3 {
  font-size: 28px;
  word-break: break-all;
}

.two-fa-modal-login .modal-footer {
  padding-top: 0;
  padding-left: 25px;
}

.responsive-profile-user {
  display: none;
}

.dashboard-profile {
  border: 5px solid #2b3553 !important;
  margin-right: 15px;
}
.manage-nfts-main-section .profile-heading{
max-width: calc(100% - 125px);
width: 100%;
max-height: 124px;
overflow-y: auto;
overflow-x: hidden;
padding: 10px;
}

.dashboard-profile img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.all-modal {
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  width: 700px !important;
  border: 0px solid #000;
  height: auto;
  margin: 0 auto;
  overflow: auto !important;
  position: absolute;
  border-radius: 8px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
.select-image-section h6{
  font-size: 20px;
  margin-left: 11px;
}
.select-image-section .card-header{
  border-bottom: 2px solid white;
  padding: 15px 15px;
}
.select-image-section input{
  margin-left: 5px;
  height: 30px;
}
.select-image-section .card-body{
  max-height: 450px;
  overflow-y: auto;
}
.select-image-section .card-body span{
  color: #fc407c;
}
.nfts-list-item{
  transition: all ease 0.3s;
  -webkit-transition: all ease 0.3s;
  -moz-transition: all ease 0.3s;
  -ms-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
}
.nfts-list-item.nft-selected{
  /* padding: 10px; */
  border: 5px solid #F8AC0D;
  border-radius: 17px;
  -webkit-border-radius: 17px;
  -moz-border-radius: 17px;
  -ms-border-radius: 17px;
  -o-border-radius: 17px;
}
.select-image-section .card-img, .card-img-bottom {
  border-radius: 11px;
  -webkit-border-radius: 11px;
  -moz-border-radius: 11px;
  -ms-border-radius: 11px;
  -o-border-radius: 11px;
}
.created-nft-header-subscription-img img {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.login-form .select__menu>div{
  max-height: 200px;
  overflow-y: auto;
}

.planListModal .card-user {
  max-height: 550px;
  overflow-y: auto;
  height: auto;
}
.timer-wrapper {
  background: #fff;
  text-align: center;
  color: #000;
  padding: 50px 0;
  min-height: calc(100vh - 230px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.timer-wrapper .timer-image {
  width: 200px;
  margin: 0 auto;
}

.timer-wrapper h2 {
  color: #000;
}
/* 1842 */
@media screen and (max-width: 1842px) {
  .table-bg {
    overflow-x: scroll;
  }
}

/* 1700 */
@media screen and (max-width: 1700px) {

  /* header */
  .container-width {
    max-width: 1320px;
  }

  /* header */
  .header {
    padding: 17px 0px;
  }

  .home-header .btn-pink,
  .main .btn-pink,
  .about-us .btn-pink {
    font-size: 15px;
    height: 46px;
    width: 174px;
  }

  .header-list .navbar-nav {
    margin-left: 32px;
  }

  .header-list .nav-link,
  .dropbtn {
    font-size: 15px;
    margin: 0px 8px;
  }

  .header-list .nav-link {
    transition: 2s all ease;
  }

  .header .navbar-brand {
    width: 11%;
  }

  /* .logo img {
    width: 100%;
  } */

  /* main */
  .main-content h5,
  .about-us-content h5 {
    font-size: 22px;
  }

  h1 {
    font-size: 45px;
    line-height: 60px;
    margin-bottom: 18px;
  }

  .main-content p,
  .about-us-content p {
    margin-bottom: 45px;
  }

  .main-buttons .btn-transparent {
    height: 48px;
    font-size: 15px;
  }

  .main {
    padding: 40px 0px 160px 0px;
  }

  /* images-section */
  .images-section {
    padding: 70px 0px 90px;
    margin-bottom: -1px;
  }

  .images-section-content-img {
    width: 65px;
    height: 60px;
    margin: 0 auto 40px;
    padding: 15px;
  }

  .images-section-content-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .images-section-content h3 {
    font-size: 23px;
  }

  .images-section-content {
    padding: 42px;
  }

  .images-section-content h3 {
    font-size: 21px;
    margin-bottom: 15px;
  }

  /* about-us */
  .about-us {
    padding: 90px 0px;
  }

  h2 {
    font-size: 40px;
  }

  /* how-it-works */
  .how-it-works {
    padding: 80px 0px 160px;
    margin-bottom: -1px;
  }

  .how-it-works-col {
    width: 240px;
    padding: 0 25px;
  }

  .how-it-works-headings {
    width: 980px;
    margin: 0 auto 100px;
  }

  .how-it-works-content h5 {
    margin-top: 30px;
    font-size: 18px;
  }

  .how-it-works-content-img {
    height: 50%;
    width: 50%;
    margin: 0 auto;
  }

  .how-works-zikzak {
    width: 91%;
  }

  /* featured-projects */
  .featured-projects {
    padding: 50px 0px;
    margin-bottom: -1px;
  }

  /* pricing-plans */
  .nav-tabs-area .ul-bg {
    width: 23%;
  }

  .pricing-plans {
    padding: 70px 0px;
    margin-bottom: -1px;
  }

  .pricing-plans-headings h6,
  .faqs-content h6 {
    margin-bottom: 25px;
  }

  h3 {
    font-size: 40px;
  }

  .tabs-content .list .list-item {
    font-size: 15px;
    line-height: 32px;
  }

  .tabs-content {
    padding: 44px;
  }

  .btn-pricing {
    font-size: 16px;
    height: 44px;
    width: 155px;
  }

  /* faqs */
  .faqs-content h2 {
    margin-bottom: 40px;
  }

  .faqs .accordion-item h4 {
    font-size: 20px;
  }

  .faqs .accordion-button {
    padding: 28px 0px;
  }

  .faqs .accordion-body {
    padding: 0px;
  }

  .faqs-form-heading h3 {
    font-size: 30px;
    margin-bottom: 40px;
  }

  .faqs-form {
    padding: 42px;
    border-radius: 8px;
  }

  .faqs-form .form-label {
    font-size: 16px;
  }

  .faqs-form-content {
    margin-bottom: 18px;
  }

  .faqs-form .form-control {
    font-size: 11px;
  }

  .faqs-form-content textarea {
    min-height: 50px;
  }

  .faqs-form-button .faqs-button {
    font-size: 16px;
    height: 44px;
    width: 170px;
  }

  .footer-platform-btn .join-btn {
    font-size: 16px;
    height: 51px;
  }

  .social-links-wrapper {
    padding-left: 150px;
  }

  /* .login-main {
        min-height: calc(100% - 210px);
    } */
  /* created-nfts */
  .created-nft-header-subscription-img {
    height: 250px;
    width: 100%;
  }

  .created-nft-header-subscription-img img {
    height: 100%;
    width: 100%;
}


  /* .table-bg {
        overflow-x: scroll;
    } */

  .total-offers h4 {
    font-size: 17px;
  }

  .your-balance-area h2 {
    font-size: 39px;
  }

  /* manage-nfts */
  .padding-avatar {
    padding: 57px 0px;
    height: 360px !important;
  }

  .manage-nfts-main-section-badges-content span {
    margin-bottom: 11px;
  }

  /* checkout form */
  .checkout-form-wrapper {
    height: calc(100vh - 214px);
  }

  .dashboard-list li a span {
    font-size: 17px;
  }

  .content-wrapper-list {
    width: 600px;
  }
}

/* 1585 */
@media screen and (max-width: 1585px) {
  .dashboard-list .list-item a svg {
    margin-right: 17px;
    width: 19px;
  }

  .dashboard-list .list-item {
    font-size: 15px;
    line-height: 57px;
  }


  .created-nft-header-input i {
    font-size: 19px;
  }

  .created-nft-header-input {
    width: 460px;
    height: 54px;
  }

  .created-nft-header-subscription .dropdown button h4 {
    font-size: 15px;
  }

  .created-nft-header-subscription .dropdown button {
    gap: 22px;
    width: 250px;
    height: 54px;
  }

  .created-nft-main-section-headings h2 {
    font-size: 23px;
  }

  .created-nft-main-section-headings a {
    font-size: 18px;
  }

  .created-nft-tabs .nav-tabs .nav-link {
    font-size: 16px;
  }

  .created-nft-header {
    margin-bottom: 45px;
  }

  .created-nft-tabs ul {
    gap: 21px;
    margin-bottom: 35px;
  }

  .created-nft-header-subscription-img {
    height: 210px;
    width: 100%;
  }

  .created-nft-tabs-description h3 {
    font-size: 19px;
    margin-bottom: 22px;
  }

  .created-nft-header-subscription-img-currency h4 {


    font-size: 15px;
  }

  .created-nft-tabs {
    margin-bottom: 35px;
  }

  .your-balance-area a {
    font-size: 16px;
    margin-bottom: 16px;
  }

  .your-balance-area h2 {
    font-size: 36px;
  }

  .total-offers h4 {
    font-size: 14px;
  }

  .total-offers-left a svg {
    width: 17px;
  }

  .light-gray-btn {
    font-size: 15px;
    height: 56px;
  }

  .log-histroy .list-item {
    font-size: 17px;
    line-height: 26px;
    padding: 21px 0px;
  }

  .log-histroy {
    padding: 32px;
  }

  /* created-nfts */
  .created-nft-header-subscription-img {
    height: 180px;
    padding: 25px;
  }

  .created-nft-tabs-description h3 {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .created-nft-header-subscription-img-currency h4 {
    font-size: 13px;
  }

  .your-balance-area a {
    font-size: 14px;
    margin-bottom: 16px;
  }

  .your-balance-area a .folder {
    width: 17px;
  }

  .your-balance-area h2 {
    font-size: 32px;
  }

  .your-balance-area {
    padding: 26px;
    margin-bottom: 35px;
  }

  .total-offers h4 {
    font-size: 13px;
  }

  .log-histroy .list-item {
    font-size: 16px;
    line-height: 25px;
    padding: 19px 0px;
  }

  /* manage-nfts */
  .padding-avatar {
    height: 300px !important;
  }

  .manage-nfts-main-section-badges h4 {
    font-size: 12px;
  }

  .h4-first {
    width: 125px;
  }

  .h4-second {
    width: 182px;
  }

  .current-plan.design .box {
    min-height: 270px;
    padding: 30px;
  }

  .dashboard-box a {
    font-size: 17px;
  }

  .tabs-list {
    padding: 18px;
  }

  .tabs-list .list-item {
    line-height: 45px;
  }

  .dashboard-list li a span {
    font-size: 16px;
  }

  .tabs-list .list-item .active::before,
  .tabs-list .list-item a:hover::before {
    left: -17px;
  }

  .profile-heading h3 {
    font-size: 22px;
  }

  .content-wrapper-list {
    width: 480px;
  }

  .faq-accordion {
    height: 460px;
  }
  .btn-theme-two {
    padding: 12px 18px;
}
}

/* 1400 */
@media screen and (max-width: 1400px) {
  .how-it-works .container {
    max-width: 1140px;
  }

  .container-width {
    max-width: 1140px;
  }

  /* header */
  .home-header .btn-pink,
  .main .btn-pink,
  .about-us .btn-pink {
    font-size: 14px;
    height: 41px;
    width: 156px;
  }

  .header-list .navbar-nav {
    margin-left: 22px;
  }

  .header .navbar-brand {
    width: 10%;
  }

  .header-list .nav-link,
  .dropbtn {
    font-size: 13px;
    margin: 0px 7px;
  }

  .header-buttons {
    gap: 10px;
  }

  /* main */
  .main-content h5,
  .about-us-content h5 {
    font-size: 20px;
  }

  h1 {
    font-size: 40px;
    line-height: 52px;
    margin-bottom: 16px;
  }

  p {
    font-size: 15px;
  }

  .main-buttons .btn-transparent {
    height: 44px;
    font-size: 14px;
  }

  .main-buttons {
    gap: 23px;
  }

  .main {
    padding: 40px 0px 120px 0px;
  }

  /* images-section */
  .images-section {
    padding: 60px 0px 80px;
  }

  .images-section-content-img {
    width: 60px;
    height: 55px;
    margin: 0 auto 34px;
    padding: 15px;
  }

  .images-section-content h3 {
    font-size: 16px;
    margin-bottom: 15px;
  }

  .images-section-content {
    padding: 20px;
  }

  /* about-us */
  .about-us {
    padding: 75px 0px;
  }

  .main-content p,
  .about-us-content p {
    margin-bottom: 40px;
  }

  .about-us-content p,
  .how-it-works-headings p,
  .footer-top-content p {
    line-height: 27px;
  }

  /* how-it-works */
  .how-it-works {
    padding: 70px 0px 130px;
    background-size: 100%;
  }

  .how-it-works-col {
    width: 220px;
    padding: 0 25px;
  }

  .how-it-works-content-img {
    height: 44%;
    width: 44%;
  }

  .how-it-works-col {
    width: 220px;
    padding: 0 17px;
  }

  .how-it-works-content {
    width: 100%;
  }

  .how-works-zikzak {
    width: 100%;
  }

  .how-it-works-headings {
    width: 980px;
    margin: 0 auto 90px;
  }

  h2 {
    font-size: 35px;
  }

  /* pricing-plans */
  .nav-tabs-area .ul-bg {
    width: 27%;
  }

  .pricing-plans-headings h6,
  .faqs-content h6 {
    margin-bottom: 20px;
  }

  h3 {
    font-size: 33px;
  }

  .tabs-content .title {
    font-size: 16px;
  }

  .btn-pricing {
    font-size: 15px;
    height: 41px;
    width: 135px;
  }

  .tabs-content {
    padding: 30px;
  }

  .tabs-content .list .list-item {
    font-size: 14px;
    line-height: 28px;
  }

  .pricing-plans {
    padding: 60px 0px;
  }

  /* faqs */
  .faqs .accordion-item h4 {
    font-size: 17px;
  }

  .faqs .accordion-button {
    padding: 24px 0px;
  }

  .faqs-content-area .accordion-button::after {
    height: 9px;
    width: 11px;
  }

  .faqs-content h2 {
    margin-bottom: 30px;
  }

  .faqs-form {
    padding: 36px;
  }

  .faqs-form-heading h3 {
    font-size: 26px;
    margin-bottom: 32px;
  }

  .faqs-form .form-label {
    font-size: 15px;
  }

  .faqs-form-content {
    margin-bottom: 16px;
  }

  .faqs-form-content textarea {
    min-height: 32px;
  }

  .faqs-form-button .faqs-button {
    font-size: 15px;
    height: 41px;
    width: 160px;
  }

  .faqs {
    padding: 60px 0px;
  }

  /* footer */
  .footer-top-content-heading h4 {
    font-size: 21px;
  }

  .footer-top ul li {
    font-size: 17px;
    line-height: 46px;
  }

  .footer-platform-btn .join-btn {
    font-size: 15px;
    height: 45px;
  }

  .social-links-wrapper {
    padding-left: 110px;
  }

  .footer-bottom {
    padding: 30px 0px;
  }

  /* login-main */
  .main-login-form-area {
    width: 33%;
    margin: 0 auto;
  }

  .main-login-form-area h2 {
    font-size: 32px;
    margin-bottom: 50px;
  }

  /* created-nfts */
  .created-nft-header-input {
    width: 310px;
    height: 48px;
  }

  .created-nft-header-subscription .dropdown button {
    gap: 18px;
    width: 241px;
    height: 48px;
  }

  .bell-icon i {
    font-size: 22px;
    height: 48px;
    width: 48px;
  }

  .emoji-icon {
    height: 48px;
    width: 48px;
  }

  .emoji-icon img {
    width: 100%;
    height: 100%;
  }

  .created-nft-header-subscription {
    gap: 20px;
  }

  .created-nft-main-section-headings h2 {
    font-size: 20px;
  }

  .created-nft-main-section-headings a {
    font-size: 17px;
  }

  .created-nft-tabs ul {
    gap: 15px;
    margin-bottom: 30px;
  }

  .created-nft-tabs .nav-tabs .nav-link {
    font-size: 15px;
  }

  .created-nft-header-subscription-img {
    height: 150px;
    padding: 20px;
  }

  .created-nft-header-subscription-img-likes h4 {
    font-size: 12px;
  }

  .created-nft-tabs-description h3 {
    font-size: 14px;
    margin-bottom: 14px;
  }

  .created-nft-header-subscription-img-currency h4 {
    font-size: 11px;
  }

  .your-balance-area h3 {
    font-size: 20px;
  }

  .your-balance-area a {
    font-size: 12px;
    margin-bottom: 13px;
  }

  .your-balance-area a .folder {
    width: 15px;
  }

  .your-balance-area h2 {
    font-size: 29px;
  }

  .your-balance-area {
    padding: 20px;
    margin-bottom: 29px;
  }

  .total-offers-left {
    gap: 4px;
  }

  .total-offers h4 {
    font-size: 11px;
  }

  .offer-yellow {
    margin-left: 6px;
  }

  .log-histroy .list-item {
    font-size: 15px;
    line-height: 23px;
    padding: 17px 0px;
  }

  .log-histroy {
    padding: 26px;
    padding-top: 10px;
  }

  .padding-avatar {
    height: 240px !important;
  }

  .manage-nfts-main-section-badges-content span {
    font-size: 10px;
  }

  .h4-first {
    width: 75px;
  }

  .h4-second {
    width: 152px;
  }

  .accordian-images-wrapper-img {
    height: 70px;
    width: 70px;
  }

  .manage-nfts-accordion h3 {
    font-size: 20px;
  }

  .manage-nfts-accordion .accordion-button {
    padding: 25px 0px;
  }

  .generate {
    height: 45px;
  }

  .current-plan.design .box {
    padding: 26px 18px;
    min-height: 240px;
  }

  .current-plan.design .heading h4 {
    font-size: 21px;
    padding-bottom: 4px;
    margin-bottom: 11px;
  }

  .current-plan ul li {
    line-height: 30px;
    font-size: 14px;
  }

  .checkout-form-wrapper {
    height: calc(100vh - 194px);
  }

  .dashboard-box a,
  .dashboard-box .wallet-info {
    font-size: 17px;
  }

  .dashboard-box .db-balance {
    font-size: 14px;
  }

  /* .modal-help .modal-dialog {
  margin: -8.25rem auto !important;
} */
  .twofa-image {
    width: 100%;
  }

  .twofamodal.modal-dialog {
    max-width: 300px;
    margin: 0 auto;
  }

  .profile-dropdown button {
    width: 45px !important;
    border-radius: 50% !important;
  }

  .modal-dark.modal-dialog {
    margin: 6.75rem auto;
  }

  .checkout-content p {
    margin-bottom: 10px !important;
  }

  .my-nfts-content {
    padding: 50px 0px;
  }

  .dashboard-profile {
    width: 84px !important;
    height: 84px !important;
  }

  .profile-heading h3 {
    font-size: 19px;
  }

  .content-wrapper-list {
    width: 360px;
  }

  .faq-accordion {
    height: 415px;
  }

  .btn-theme {
    padding: 15px 20px;
    font-size: 13px;
  }
  .btn-theme-two {
    padding: 12px 16px;
    font-size: 13px;
}
}

/* 1199 */
@media screen and (max-width: 1199px) {
  .how-it-works .container {
    max-width: 960px;
  }

  .container-width {
    max-width: 960px;
  }

  /* header */
  .btn-pink {
    font-size: 12px;
    height: 35px;
    width: 132px;
  }

  .header .navbar-brand {
    width: 13%;
  }

  .header-list .navbar-nav {
    margin-left: 8px;
  }

  .header-list .nav-link,
  .dropbtn {
    font-size: 12px;
    margin: 0px 3px;
  }

  .header-buttons {
    gap: 5px;
  }

  /* main */
  .main-content h5,
  .about-us-content h5 {
    font-size: 18px;
  }

  h1 {
    font-size: 30px;
    line-height: 41px;
    margin-bottom: 15px;
  }

  .main-buttons .btn-pink {
    font-size: 14px;
    height: 43px;
    width: 132px;
  }

  .main-content p,
  .about-us-content p {
    margin-bottom: 32px;
  }

  .main-buttons {
    gap: 18px;
  }

  /* images-section */
  .images-section-content-img {
    width: 55px;
    height: 50px;
    margin: 0 auto 25px;
    padding: 13px;
  }

  .images-section-content {
    padding: 28px;
  }

  .images-section-content p {
    font-size: 14px;
  }

  .images-section-content h3 {
    font-size: 16px;
    margin-bottom: 13px;
  }

  .images-section {
    padding: 50px 0px 70px;
  }

  /* about-us */
  .about-us {
    padding: 55px 0px;
  }

  .about-us-content p,
  .how-it-works-headings p,
  .footer-top-content p {
    line-height: 24px;
  }

  /* how-it-works */
  h2 {
    font-size: 30px;
  }

  .how-it-works-headings {
    width: 850px;
    margin: 0 auto 60px;
  }

  .how-it-works-content h5 {
    margin-top: 20px;
    font-size: 16px;
  }

  .how-it-works-col {
    width: 187px;
    padding: 0px 10px;
  }

  .how-it-works-content-img {
    height: 40%;
    width: 40%;
  }

  .how-it-works-content p {
    color: #ffffff91;
    margin-bottom: 15px;
    font-size: 13px;
  }

  .how-it-works {
    padding: 60px 0px 100px;
  }

  /* featured-projects */
  .featured-projects-button .btn-pink {
    font-size: 14px;
    height: 43px;
    width: 225px;
  }

  /* pricing-plans */
  .nav-tabs-area .nav-tabs .nav-item.show .nav-link,
  .nav-tabs-area .nav-tabs .nav-link.active {
    height: 40px;
    width: 126px;
  }

  .nav-tabs-area .nav-link {
    font-size: 14px;
  }

  .tabs-content .most-popular {
    top: -18px;
    height: 32px;
    width: 114px;
    font-size: 13px;
  }

  h3 {
    font-size: 26px;
  }

  .tabs-content .title {
    font-size: 15px;
  }

  .btn-pricing {
    font-size: 14px;
    height: 38px;
    width: 127px;
  }

  .pricing-plans-headings h6,
  .faqs-content h6 {
    margin-bottom: 15px;
    font-size: 17px;
  }

  /* faqs */
  .faqs-content-area {
    padding-right: 60px;
  }

  .faqs-content h2 {
    margin-bottom: 20px;
  }

  .faqs-form {
    padding: 27px;
  }

  .faqs-form-heading h3 {
    font-size: 22px;
    margin-bottom: 25px;
  }

  .faqs-form .form-label {
    font-size: 14px;
  }

  /* footer */
  .footer-logo {
    width: 40%;
  }

  .footer-logo img {
    width: 100%;
  }

  .social-links-wrapper {
    padding-left: 70px;
  }

  .footer-platform-btn .join-btn {
    font-size: 14px;
    height: 42px;
  }

  .footer-top-content-heading h4 {
    font-size: 19px;
  }

  .social-links {
    gap: 16px;
    margin-bottom: 72px;
  }

  .footer-top {
    padding: 40px 0px;
  }

  /* login-main */
  .main-login-form-area {
    width: 39%;
  }

  /* created-nfts */
  .created-nft-header-input {
    width: 0px;
    height: 0px;
  }

  .created-nft-header-input i {
    position: absolute;
    left: 25px;
    font-size: 17px;
  }

  .created-nft-header-input input {
    padding-left: 56px;
  }

  .created-nft-header-subscription .dropdown button {
    gap: 13px;
    width: 221px;
    height: 44px;
  }

  .created-nft-header-subscription .dropdown button h4 {
    font-size: 14px;
  }

  .created-nft-header-subscription-img {
    height: 170px;
    padding: 28px;
  }

  .created-nft-header-subscription-img-likes h4 {
    font-size: 14px;
  }

  .created-nft-tabs-description h3 {
    font-size: 16px;
    margin-bottom: 17px;
  }

  .created-nft-header-subscription-img-currency h4 {
    font-size: 12px;
  }

  .table-bg {
    margin-bottom: 40px;
  }

  .your-balance-area {
    padding: 30px;
  }

  .your-balance-area a {
    font-size: 15px;
    margin-bottom: 13px;
  }

  .total-offers h4 {
    font-size: 15px;
  }

  .total-offers-left {
    gap: 12px;
  }

  .file-uploader {
    margin-bottom: 20px;
  }

  .checkout-content p {
    margin-bottom: 8px !important;
    font-size: 18px;
  }

  .checkout-content a {
    font-size: 18px;
  }

  .profile-user {
    display: none;
  }

  .responsive-profile-user {
    display: block;
  }

  .tabs-list .list-item .active::before,
  .tabs-list .list-item a:hover::before {
    display: none;
  }

  .tabs-list .list-item {
    line-height: 28px;
    margin-bottom: 10px;
  }

  .content-wrapper-list {
    width: 450px;
  }

  .user-box-wrapper {
    margin: 15px !important;
  }

  .btn-theme {
    padding: 15px 16px;
    font-size: 12px;
  }
  .btn-white {
    padding: 12px 16px !important;
}
.created-nft-header-subscription .dropdown button h4 {
    font-size: 13px;
  }
  .footer-top .social-links li {
    width: 43px;
}
}

/* 991 */
@media screen and (max-width: 991px) {
  .how-it-works .container {
    max-width: 720px;
  }

  /* header */
  .login-main {
    min-height: calc(100vh - 68px - 81px);
  }

  .header {
    padding: 5px 0px;
  }

  .container-width {
    max-width: 720px;
  }

  .toggler-icon {
    color: #fb3a75;
  }

  .header-list {
    padding: 30px 0px;
    gap: 0px;
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 9;
    background: #000;
    top: 50px;
  }

  .header-list .nav-link,
  .dropbtn {
    margin: 0px;
    font-size: 14px;
  }

  .header-list .navbar-nav {
    margin-left: 17%;
    margin-bottom: 0px !important;
  }

  .header-buttons .nav-item .nav-link {
    padding-left: 0px;
  }

  .header-buttons {
    padding: 0px !important;
  }

  .btn-pink {
    font-size: 14px;
    height: 38px;
    width: 152px;
  }

  .header-buttons.header-list {
    display: none;
  }

  .responsive-header {
    display: block !important;
  }

  .responsive-header.header-buttons {
    display: inline-flex !important;
    align-items: center;
    flex-direction: row;
    gap: 8px;
  }

  .responsive-header .header-icons {
    font-size: 16px;
    color: #fb3a75;
  }

  .header .navbar-toggler {
    margin-left: auto;
  }

  .created-nft-right-area .toggler-bars {
    display: block;
  }

  .dropbtn {
    padding: 0px;
  }

  .responsive-header .nav-item {
    margin-right: 12px;
  }

  .header-list .dropdown {
    display: none;
  }

  .dropdown.profile-dropdown.dropdown {
    display: block !important;
  }

  .mobile-drop {
    display: block;
  }

  /* main */
  .main-content {
    padding-right: 0px;
  }

  /* images-section */
  .images-section-content {
    min-height: 260px;
    margin-bottom: 30px;
  }

  .main-col-img {
    display: flex;
    justify-content: center;
  }

  /* about-us */
  .about-us-content {
    margin-bottom: 60px;
  }

  /* how-it-works */
  .how-it-works-headings {
    width: 600px;
    margin: 0 auto 60px;
  }

  .reponsive-works h5 {
    margin-top: 0px;
  }

  .reponsive-works p {
    color: #ffffff91;
  }

  .reponsive-works-area {
    display: block;
  }

  .how-it-works-row {
    display: none !important;
  }

  .how-it-works-content-img {
    width: 100%;
    margin-bottom: 20px;
  }

  .how-it-works {
    padding: 60px 0px;
  }

  /* pricing-plans */
  .nav-tabs-area .ul-bg {
    width: 33%;
  }

  .nav-tabs-area .nav-tabs .nav-item.show .nav-link,
  .nav-tabs-area .nav-tabs .nav-link.active {
    height: 36px;
    width: 108px;
  }

  .nav-tabs-area .nav-link {
    font-size: 13px;
  }

  .tabs-content {
    padding: 30px;
    margin-bottom: 50px;
  }

  .faqs-content-area {
    padding-right: 0px;
    margin-bottom: 60px;
  }

  .pricing-plans {
    padding: 60px 0px 10px;
  }

  .faqs-form {
    padding: 45px;
    width: 70%;
    margin: 0 auto;
  }

  .footer-logo {
    width: 20%;
  }

  .margin-content {
    margin-bottom: 32px;
  }

  .footer-top-content p {
    margin-bottom: 50px;
  }

  .social-links-wrapper {
    padding-left: 0px;
  }

  /* login-form */
  .main-login-form-area {
    width: 50%;
  }

  .main-login-form-area .nav-tabs-area .nav-tabs .nav-item.show .nav-link,
  .main-login-form-area .nav-tabs .nav-link.active {
    height: 41px;
    width: 162px;
  }

  .main-login-form-area .nav-tabs .nav-link {
    font-size: 16px;
  }

  /* create-nfts */
  .created-nft-right-area {
    width: 100%;
  }

  .dashboard-area {
    position: absolute;
    height: 100vh;
  }

  .navbar .navbar-wrapper {
    margin-left: 58px;
  }

  .content-wrapper-list {
    width: 510px;
  }

  .faq-accordion {
    height: 350px;
  }

  .video-container #videoLink,
  .video-container .react-player__preview {
    height: 300px !important;
  }

  .content.all-list {
    padding-left: 0px !important;
    padding-right: 0px;
  }
  .profile-dropdown .dropdown-toggle::after {
    margin-left: 0px !important;
    display: none;
  }
  .btn-white::after {
    display: none;
  }
  .dashboard-area {
    left: -85%;
  }
  .footer-top .social-links li {
    width: 41px;
}
}

/* 767 */
@media screen and (max-width: 767px) {
  .how-it-works .container {
    max-width: 540px;
  }

  /* header */
  .container-width {
    max-width: 540px;
  }

  .header .navbar-brand {
    width: 18%;
  }

  /* main */
  .main {
    padding: 40px 0px 70px 0px;
  }

  /* images-section */
  .images-section-content-img {
    width: 60px;
    height: 55px;
  }

  .images-section {
    padding: 50px 0px;
  }

  .images-section-content h3 {
    font-size: 19px;
  }

  /* how-it-works */
  .how-it-works-headings {
    width: 460px;
    margin: 0 auto 50px;
  }

  .how-it-works-content-img {
    width: 20%;
    margin-bottom: 20px;
  }

  .how-it-works {
    padding: 40px 0px;
  }

  .how-it-works-content h5 {
    font-size: 20px;
  }

  .reponsive-works p {
    font-size: 15px;
  }

  .how-it-works-content {
    padding: 0px 20px;
  }

  /* pricing-plans */
  .nav-tabs-area .ul-bg {
    width: 36%;
    gap: 8px;
    height: 54px;
  }

  .nav-tabs-area .nav-tabs .nav-item.show .nav-link,
  .nav-tabs-area .nav-tabs .nav-link.active {
    height: 33px;
    width: 93px;
  }

  .nav-tabs-area .nav-link {
    font-size: 12px;
  }

  /* faqs */
  .faqs {
    padding: 40px 0px;
  }

  .faqs-form {
    width: 80%;
  }

  /* footer */
  .home-footer.footer {
    text-align: left;
  }

  .social-links {
    margin-bottom: 42px;
  }

  .social-links-wrapper {
    padding-right: 220px;
  }

  /* login-main */
  .main-login-form-area {
    width: 65%;
  }

  .main-login-form-area .nav-tabs-area .nav-tabs .nav-item.show .nav-link,
  .main-login-form-area .nav-tabs .nav-link.active {
    height: 40px;
    width: 151px;
  }

  .main-login-form-area .nav-tabs .nav-link {
    font-size: 15px;
  }

  .created-nft-header-input {
    width: 160px;
    height: 40px;
  }

  .created-nft-header-subscription .dropdown button {
    gap: 11px;
    width: 191px;
    height: 40px;
  }

  .created-nft-header-subscription .dropdown button h4 {
    font-size: 13px;
  }

  .bell-icon i {
    font-size: 18px;
    height: 40px;
    width: 40px;
  }

  .emoji-icon img {
    height: 100%;
    width: 100%;
  }

  .created-nft-header-input input::placeholder {
    font-size: 13px;
  }

  .created-nft-header-input input {
    padding-left: 52px;
  }

  .created-nft-header-input i {
    left: 25px;
    font-size: 15px;
  }

  .created-nft-header-subscription-img {
    height: auto;
    padding: 50px;
  }

  .created-nft-header-subscription-img-likes h4 {
    font-size: 18px;
  }

  .created-nft-tabs-description h3 {
    font-size: 22px;
  }

  .created-nft-header-subscription-img-currency h4 {
    font-size: 16px;
  }

  .created-nft-tabs-description {
    margin-bottom: 40px;
  }

  .accordian-images-wrapper-img {
    height: 60px;
    width: 60px;
    padding: 6px;
  }

  .h4-second {
    width: 132px;
  }

  .h4-first {
    width: 75px;
  }

  .manage-nfts-main-section-badges-content span {
    font-size: 10px;
    margin: 0px 2px 11px;
  }

  .content-wrapper-list {
    width: 100%;
  }

  .checkout-form-box {
    width: 500px;
  }

  .role-user .has-label.form-group {
    width: 100% !important;
  }

  .upload-multi-label {
    width: 100% !important;
    margin-bottom: 15px;
  }

  .w-input {
    width: 100%;
  }

  .login-form .has-label.form-group.w-45 {
    width: 48% !important;
  }

  .login-form .w-45 {
    width: 100% !important;
  }
  .all-modal {
    width: 100% !important;
    background-color: none !important;
}
.dashboard-area {
  left: -85%;
}

}

/* 574 */
@media screen and (max-width: 574px) {
  .how-it-works .container {
    max-width: 100%;
  }

  .container-width {
    max-width: 90%;
  }

  /* header */
  .header .navbar-brand {
    width: 22%;
  }

  .logo {
    width: 60px;
  }

  /* main */
  .main-buttons .btn-pink {
    font-size: 13px;
    height: 40px;
    width: 115px;
  }

  .main-buttons .btn-transparent {
    height: 41px;
    font-size: 13px;
    width: 140px;
  }

  /* images-section */
  .images-section {
    padding: 30px 0px 0px;
  }

  h1 {
    font-size: 27px;
    line-height: 39px;
    margin-bottom: 15px;
  }

  /* about-us */
  .about-us-content {
    margin-bottom: 20px;
  }

  .about-us {
    padding: 15px 0px;
  }

  /* how-it-works */
  .how-it-works-headings {
    width: 80%;
    margin: 0 auto 50px;
  }

  .how-it-works-content-img {
    width: 32%;
    margin-bottom: 15px;
  }

  h2 {
    font-size: 25px;
  }

  /* pricing-plans */
  .nav-tabs-area .nav-tabs .nav-item.show .nav-link,
  .nav-tabs-area .nav-tabs .nav-link.active {
    height: 30px;
    width: 100%;
  }

  .nav-tabs-area .ul-bg {
    width: 62%;
    gap: 10px;
    height: 50px;
  }

  .nav-tabs-area .nav-link {
    font-size: 11px;
  }

  /* featured-projects */
  .featured-projects .owl-nav button img {
    height: 42px;
    width: 42px;
  }

  .featured-projects button.owl-next {
    right: -17px;
  }

  .featured-projects button.owl-prev {
    left: -17px;
  }

  /* faqs */
  .faqs {
    padding: 30px 0px;
  }

  .faqs .accordion-item h4 {
    font-size: 16px;
  }

  .faqs-form {
    width: 100%;
    padding: 25px;
  }

  .social-links-wrapper {
    padding-right: 60px;
  }

  .footer-logo {
    width: 35%;
  }

  /* login-form */
  /* .login-main {
        min-height: calc(100% - 155px);
    } */
  .main-login-form-area h2 {
    font-size: 20px;
    margin-bottom: 30px;
  }

  .main-login-form-area {
    width: 92%;
  }

  .main-login-form-area .nav-tabs-area .nav-tabs .nav-item.show .nav-link,
  .main-login-form-area .nav-tabs .nav-link.active {
    height: 36px;
    width: 136px;
  }

  .main-login-form-area .nav-tabs .nav-link {
    font-size: 13px;
  }

  .login-input-wrapper label {
    font-size: 16px;
  }

  .login-input-wrapper {
    margin-bottom: 28px;
  }

  .login-form .login-btn {
    height: 44px;
    margin-bottom: 32px;
  }

  .main-login-form-area .ul-login-tabs {
    gap: 4px;
    margin-bottom: 33px;
  }

  /* created-nfts */
  .created-nft-header-subscription .dropdown {
    display: none;
  }

  .created-nft-right-area {
    padding: 20px 10px;
  }

  .created-nft-main-section-headings h2 {
    font-size: 16px;
  }

  .created-nft-main-section-headings a {
    font-size: 15px;
  }

  .created-nft-header-subscription-img-likes h4 {
    font-size: 16px;
  }

  .created-nft-tabs-description h3 {
    font-size: 20px;
  }

  .mobile-headings h2 {
    font-size: 20px;
  }

  .your-balance-area {
    padding: 22px;
  }

  .your-balance-area a {
    font-size: 13px;
    margin-bottom: 13px;
  }

  .your-balance-area h2 {
    font-size: 27px;
  }

  .total-offers h4 {
    font-size: 12px;
  }

  .light-gray-btn {
    font-size: 14px;
    height: 50px;
  }

  .created-nft-header-input {
    display: none;
  }

  .mobile-header-input {
    display: block;
    width: 100%;
  }

  .created-nft-header {
    margin-bottom: 30px;
    flex-flow: column;
    gap: 20px;
  }

  /* manage-nfts */
  .accordion-body {
    padding: 0;
  }

  .select-content select {
    width: 100%;
  }

  .manage-nfts-main-section {
    padding: 20px;
  }

  .manage-nfts-accordion h3 {
    font-size: 18px;
  }

  .created-nft-header-subscription-img {
    padding: 35px;
  }

  .uploader-content {
    padding: 20px;
  }

  .manage-nfts-main-section-badges {
    margin-bottom: 40px;
    flex-direction: column;
    gap: 20px;
  }

  .h4-first {
    width: 100%;
  }

  .h4-second {
    width: 100%;
  }

  .manage-nfts-main-section-badges h4 {
    font-size: 14px;
  }

  /* faq */
  .faqs .accordion-item h4 {
    font-size: 16px;
    padding-right: 40px;
  }

  .mobile-header-input {
    position: relative;
    display: none;
  }

  .mobile-header-input svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
  }

  .card .card-header .card-title {
    font-size: 21px;
    margin-bottom: 10px;
  }

  .user-icons .btn-theme {
    padding: 10px 18px;
    font-size: 14px;
  }

  .dashboard-profile {
    width: 74px !important;
    height: 62px !important;
  }

  .userProfile-class .card-body {
    padding: 0px;
  }

  .userProfile-class .manage-nfts-main-section {
    padding: 20px;
  }

  .userProfile-class .form-group {
    width: 100% !important;
  }

  .privacy-list {
    padding: 28px 25px;
  }

  .privacy-list ul li {
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 18px;
  }

  .privacy-list ul li::before {
    height: 8px;
    width: 8px;
    top: 12px;
  }

  .faq-accordion {
    height: 430px;
  }

  .checkout-form-wrapper {
    height: calc(100vh - 145px);
  }

  .checkout-form-box {
    width: 100%;
  }

  .checkout-form-wrapper h2 {
    font-size: 26px;
  }

  .footer-bottom-content p {
    font-size: 13px;
  }

  .input-wrapper-handler {
    padding: 20px 15px;
  }

  .created-nft-header-subscription .address {
    width: 140px;
    font-size: 13px;
  }

  .created-nft-header-subscription {
    width: 100%;
    justify-content: flex-end;
  }

  .card label,
  label {
    font-size: 13px;
  }

  .brand-text {
    display: none;
  }
  .main-panel .content {
    padding-left: 20px !important;
  }
  .all-modal {
    height: 600px;
    overflow-y: auto;
  }
  .dashboard-area {
    left: -115%;
    width: 280px;
  }
  .created-nft-right-area .toggler-bars{
    height: 20px;
    width: 25px;
  }
  .cb-wrapper {
    padding: 35px 0px 0px;
}
.login-form .has-label.form-group.w-45 {
  width: 100% !important;
}
}